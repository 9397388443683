import axios from "axios"
import { getStorage, clear } from "../storage"
import md5 from "js-md5";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

export let sign = (data)=>{
  let key = "schoolemp2022"; //签名Key
  let keys = [];
  console.log(data)
  for (let k in data){
      if (k == 'sign' || k == 'undefined' || k == '0' || k == 0 || k == 'null' || k ==null){
          continue;
      }
      keys.push(k);
  }
  //排序
  keys.sort();
  console.log(keys)
  let kv = [];
  for (let k of keys){
    if (data[k] == 'undefined' || data[k] == '0' || (data[k] == 0 && data[k] !== '0.00') || data[k] == 'null' || data[k] ==null || (typeof data[k]) == 'object'){
      continue;
    }
    kv.push(k + '=' +  unescape(data[k])) //urlencode编码
  }
  //

  let signStr = kv.join('&');
  console.log(signStr)
  console.log(99)
  signStr = signStr + "&key="+key;
  signStr = md5(signStr)
  return signStr;
}

// 添加请求拦截器
http.interceptors.request.use(function (req) {
  console.log(req)
  if(req.method == 'get'){
    req.data = req.params;
    req.data.timestamp = new Date().getTime();
  }else{
    req.data.timestamp = new Date().getTime();
    if(req.data.type == 'upload'){
      req.data.formData.append('sign',sign({timestamp:req.data.timestamp}));
      req.data.formData.append('timestamp', req.data.timestamp);
      req.data = req.data.formData;
    }

  }
  
  req.data.sign = sign(req.data);        
  // 添加时间戳和签名

  req.headers.token = getStorage("token")
  req.headers.Content_Type = "text/html; charset=utf-8"
  req.headers.client_type = 1
  req.headers.client_version = "1.0.0"
  
  
  return req;

})

// 添加响应拦截器
http.interceptors.response.use(function (res) {
  // console.log(res.data)
  const {code, msg, data} = res.data
  if(code === -10000) {
    clear(); // 如果token过期就删除token
  }
  return res.data
}, function (res) {
  //this.$message.error('请求失败！')
  return Promise.reject(res.data)
})

export default http
