<template>
  <el-container class="layout__wrap">
    <el-header class="header__wrap" height="60px">
      <div class="logo__wrap">
        <img class="logo" src="@/assets/img/logo.png" alt="">
        <!-- <div class="J_menutoggle" @click="toggleColl">
          <el-tooltip
            effect="dark"
            :content="isCollapse ? `展开` : `收起`"
            placement="bottom">
            <i class="el-icon-menu" color="#fff"></i>
          </el-tooltip>
        </div> -->
        <span class="logoText">校园聘后台管理系统</span>
      </div>
      <div class="avatar__wrap">
        <span class="userName">您好，{{account}}</span>
        <span class="exit" @click="logout">
          <el-image
            style="width: 17px; height: 17px;margin-right:5px;"
            :src="require('@/assets/img/exit.png')"
            fit="cover"></el-image>
          退出
        </span>
      </div>
    </el-header>
    <el-container>
      <NaviBar :isCollapse="isCollapse"></NaviBar>
      <el-main class="layout__main">
        <!-- <page-header></page-header> -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import PageHeader from './PageHeader.vue';
import NaviBar from './NaviBar';
import { getStorage, clear } from "@/storage"
export default {
  name: 'LayoutContainer',
  components: {
    NaviBar,
    PageHeader
  },
  data() {
    return {
      isCollapse: false,
      toggleMenu: false,
      account: getStorage('account'),
      userName: getStorage('userName')
    }
  },
  methods: {
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/admin/logout',{params: {}}).then(res => {
              clear(); // 删除token
              window.location.assign('/login')
            })
            done()
          } else {
            done()
          }
        }
      }).catch(err => {})
    },
    // 展开、收起
    toggleColl() {
      this.isCollapse = !this.isCollapse;
      this.toggleMenu = !this.toggleMenu;
      if (this.isCollapse) {
        this.isbigger = "J_bigger";
      } else {
        this.isbigger = "";
      }
    },
  }
}
</script>

<style scoped lang="scss">
.layout__wrap {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  .header__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme;
    // background-image: linear-gradient(to right, $theme, #0bCFFF);
    .logo__wrap {
      display: flex;
      align-items: center;
      .logo {
        width: 100px;
      }
      .logoText {
        color: #fff;
        margin-left: 80px;
        font-size: 20px;
        font-weight: bold;
      }
      .J_menutoggle {
        color: #fff;
        margin-left: 20px;
      }
    }
    .avatar__wrap {
      color: #fff;
      display: flex;
      
      .userName {
        margin-right: 20px;
      }
      .exit {
        cursor: pointer;
        display: flex;
      }
    }
  }
  .layout__main {
    display: flex;
    flex-direction: column;
    padding: 0;

    .page-header__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      color: #595959;

      .title {
        height: 58px;
        line-height: 58px;
        
      }
    }

    &>div {
      flex: 1;
      min-height: 0;
      overflow: hidden;
    }

  }
}
</style>
