<template>
  <el-aside width="200px">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      active-text-color="#30C3B1"
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="true">

      <el-menu-item v-for="(item,index) in routes" :key="index" :index="item.path">
        <div class="menu-item">
          <!-- <i class="el-icon-menu"></i> -->
          <div :class="index === isActive ? 'menu-item-box active' : 'menu-item-box'" @click="handleItem(item,index)">
            <el-image
              class="menu-icon"
              style="width: 20px; height: 20px"
              :src="item.meta.icon"
              fit="cover"></el-image>
            <span slot="title">{{item.meta.title}}</span>
          </div>
        </div>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import { remove } from '@/storage/index.js'
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      isActive: 0,
      routes: [],
    }
  },
  mounted() {
    this.routes = this.$router.options.routes.slice(2, this.$router.options.routes.length - 1)
  },
  methods: {
    handleItem(item,index) {
      console.log(item.path)
      console.log(this.$router.path)
      this.isActive = index;
      remove('companyStatus')
      remove('schoolPage')
    }
  }
}
</script>
<style scoped lang="scss">
.active {
  border-radius: 6px;
  box-shadow: 1px 10px 15px 0px rgb(48 195 177 / 20%)
}
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
    .el-menu-item i {
      color: #4F9A96;
    }
    .el-menu-item {
      color: #4F9A96;
    }
    .el-menu-item:hover {
      box-shadow: #eee;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }

  .el-menu-item {
    background: #fff;
  }
  .menu-item-box {
    margin:10px auto;
    padding:0 10px;
  }
  .menu-item-box:hover {
    border-radius: 6px;
    box-shadow: 1px 10px 15px 0px rgb(48 195 177 / 20%)
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}


</style>
