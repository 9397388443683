import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "首页管理",
      icon: require('@/assets/img/nav/1.png'),
    },
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'HomeBanner',
        meta: {
          title: 'banner管理'
        },
        component: () => import("@/views/home/banner")
      },
      {
        path: '/notify',
        name: 'HomeNotify',
        meta: {
          title: '系统通知'
        },
        component: () => import("@/views/home/notify")
      }
    ]
  },
  {
    path: "/school",
    name: "School",
    meta: {
      title: "学校管理",
      icon: require('@/assets/img/nav/2.png'),
    },
    component: Layout,
    children: [
      {
        path: "/school",
        name: "SchoolList",
        meta: {
          title: '学校列表'
        },
        component: () => import("@/views/schoolManage/school/index")
      },
      {
        path: "/faculty",
        name: "FacultyList",
        meta: {
          title: '院系列表'
        },
        component: () => import("@/views/schoolManage/faculty/index")
      },
      {
        path: "/major",
        name: "MajorList",
        meta: {
          title: '专业列表'
        },
        component: () => import("@/views/schoolManage/major/index")
      },
      {
        path: "/classes",
        name: "ClassesList",
        meta: {
          title: '班级列表'
        },
        component: () => import("@/views/schoolManage/classes/index")
      }
    ]
  },
  {
    path: "/company",
    name: "Company",
    meta: {
      title: "企业管理",
      icon: require('@/assets/img/nav/2.png'),
    },
    component: Layout,
    children: [
      {
        path: "/company",
        name: "Company",
        meta: {
          title: "企业列表"
        },
        component: () => import("@/views/company/index")
      },
      {
        path: "/quarters",
        name: "Quarters",
        meta: {
          title: "岗位列表"
        },
        component: () => import("@/views/company/quarters/index")
      },
      {
        path: "/account",
        name: "Account",
        meta: {
          title: "账号信息"
        },
        component: () => import("@/views/company/account/index")
      }
    ]
  },
  {
    path: "/user",
    name: "User",
    meta: {
      title: "用户管理",
      icon: require('@/assets/img/nav/3.png'),
    },
    component: Layout,
    children: [
      {
        path: "/user",
        name: "User",
        meta: {
          title: "用户列表",
        },
        component: () => import("@/views/user/index")
      },
      {
        path: "/recordList",
        name: "RecordList",
        meta: {
          title: "操作记录",
        },
        component: () => import("@/views/user/recordList.vue")
      },
      {
        path: "/userInfo",
        name: "UserInfo",
        meta: {
          title: "简历信息",
        },
        component: () => import("@/views/user/userInfo.vue")
      }
    ]
  },
  {
    path: "/agent",
    name: "Agent",
    meta: {
      title: "代理管理",
      icon: require('@/assets/img/nav/4.png'),
    },
    component: Layout,
    children: [
      {
        path: "/agent",
        name: "AgentList",
        meta: {
          title: '代理列表'
        },
        component: () => import("@/views/agentManage/agent/index"),
      },
      {
        path: "/agent/salesman",
        name: "salesmanList",
        meta: {
          title: '业务员列表'
        },
        component: () => import("@/views/agentManage/salesman/index"),
      },
      {
        path: "/agent/order",
        name: "OrderList",
        meta: {
          title: '交易明细'
        },
        component: () => import("@/views/agentManage/order/index"),
      }
    ]
  },
  {
    path: "/finance",
    name: "Finance",
    meta: {
      title: "财务管理",
      icon: require('@/assets/img/nav/6.png'),
    },
    component: Layout,
    children: [
      {
        path: '/finance',
        name: 'FinanceList',
        meta: {
          title: '财务列表'
        },
        component: () => import("@/views/finance/index"),
      }
    ]
  },
  {
    path: "/report",
    name: "Report",
    meta: {
      title: "举报管理",
      icon: require('@/assets/img/nav/7.png'),
    },
    component: Layout,
    children: [
      {
        path: "/report",
        name: "Report",
        meta: {
          title: "举报列表"
        },
        component: () => import("@/views/report/index"),
      }
    ]
  },
  {
    path: "/platform",
    name: "Platform",
    meta: {
      title: "平台设置",
      icon: require('@/assets/img/nav/5.png'),
    },
    component: Layout,
    children: [
      {
        path: "/platform",
        name: "PlatformList",
        meta: {
          title: "人才币规则"
        },
        component: () => import("@/views/platformRule/index"),
      },
      {
        path: "/brokerage",
        name: "brokerageList",
        meta: {
          title: "佣金规则"
        },
        component: () => import("@/views/platformRule/brokerage.vue"),
      }
    ]
  },
  {
    path: "/feedback",
    name: "Feedback",
    meta: {
      title: "意见反馈",
      icon: require('@/assets/img/nav/5.png'),
    },
    component: Layout,
    children: [
      {
        path: "/feedback",
        name: "Feedback",
        meta: {
          title: "反馈列表"
        },
        component: () => import("@/views/feedback/index"),
      }
    ]
  },
  {
    path: "/jobs",
    name: "Jobs",
    meta: {
      title: "岗位管理",
      icon: require('@/assets/img/nav/5.png'),
    },
    component: Layout,
    children: [
      {
        path: "/jobs",
        name: "Jobs",
        meta: {
          title: "岗位列表"
        },
        component: () => import("@/views/jobs/index"),
      }
    ]
  },
  {
    path: "/weal",
    name: "Weal",
    meta: {
      title: "福利待遇",
      icon: require('@/assets/img/nav/5.png'),
    },
    component: Layout,
    children: [
      {
        path: "/weal",
        name: "Weal",
        meta: {
          title: "福利待遇"
        },
        component: () => import("@/views/weal/index"),
      }
    ]
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]



